import React from "react"
import LayoutRoot from "../components/LayoutRoot"
import styled from "styled-components"
import { FlexContainer } from "../styles/layout.styled"
import { useInView } from "react-intersection-observer"
import Tabs from "../components/OurWork/Tabs"
import { getParams } from "../utils"
import { OUR_WORK_NODES, OUR_WORK_TABS } from "../constants"

const OurWorkContainer = styled(FlexContainer)`
  flex-direction: column;
`

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  margin: 70px 0;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0) scale(1)" : "translateX(500px) scale(0)"};
  transition: all 1s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(250px) scale(0)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0) scale(1)" : "translateX(100px) scale(0)"};
  }
`

const PageContent = styled(FlexContainer)`
  background: #132436;
`

const OurWorkPage = ({ location: { search } }) => {
  const { ref: titleRef, inView: titleInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false,
  })

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <LayoutRoot>
      <OurWorkContainer>
        <FlexContainer bg="#193048" padding="81px 0 0">
          <PageTitle ref={titleRef} inView={titleInView}>
            VIEW BY SERVICE
          </PageTitle>
        </FlexContainer>
        <PageContent>
          <Tabs
            tabs={OUR_WORK_TABS}
            currentTab={
              !getParams(search, "project")
                ? "all-projects"
                : getParams(search, "project")
            }
            nodes={OUR_WORK_NODES}
          />
        </PageContent>
      </OurWorkContainer>
    </LayoutRoot>
  )
}

export default OurWorkPage
