import React, { useEffect } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { Link } from "gatsby"

const MainContainer = styled(FlexContainer)`
  flex-direction: column;
`

const TabContent = styled(FlexContainer)`
  max-width: 1150px;
  justify-content: space-between;
  margin: 66px 0 77px;
  padding: 20px 10px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
const Title = styled(Link)`
  position: relative;
  font-size: 16px;
  white-space: nowrap;
  margin: 0;
  font-weight: ${({ selected }) => (selected ? "bold" : "100")};
  color: ${({ selected, theme: { white, orange } }) =>
    selected ? orange : white};
  cursor: pointer;
  transition: all 0.4s ease;
  text-decoration: none;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 50%;
    height: 2px;
    top: 0;
    left: 0;
    transform: translate(50%, 30px);
    background: ${({ theme: { orange } }) => orange};
    visibility: ${({ selected }) => (selected ? "visible" : "hidden")};
  }
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    margin-right: ${({ lastElementMargin }) =>
      lastElementMargin ? 0 : "20px"};
  }
`

const Tabs = ({ tabs, currentTab = "", nodes }) => {
  useEffect(() => {
    if (window.innerWidth <= 925) {
      const element = document.getElementById(currentTab)
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      })
    }
  }, [currentTab])

  return (
    <MainContainer>
      <FlexContainer padding="0 20px">
        <TabContent>
          {tabs.map(({ id, name, location }) => (
            <Title
              activeClassName="active"
              to={`/our-work/?project=${location}`}
              key={id}
              selected={location === currentTab}
              id={location}
            >
              {name}
            </Title>
          ))}
        </TabContent>
      </FlexContainer>
      {nodes.map(
        ({ id, location, Node }) => location === currentTab && <Node key={id} />
      )}
    </MainContainer>
  )
}

export default Tabs
